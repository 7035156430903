import { Auth, Amplify } from 'aws-amplify';

/*
Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_Qtfo45DQ0',
        userPoolWebClientId: '5cbmct7nn4igp4udlnk1vli9iv'
    },
    oauth: {
        domain: 'yamana-non-prod.auth.us-east-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://localhost:3000',
        redirectSignOut: 'https://localhost:3000',
        responseType: 'code' //'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
});
*/


Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: 'us-east-1_iG8O5awt4',
        userPoolWebClientId: '5kf08hkh4qj6tjh470bal0ctrp'
    },
    oauth: {
        domain: 'yamana-public-prod.auth.us-east-1.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://appservice.panamericansilver.com',
        redirectSignOut: 'https://appservice.panamericansilver.com',
        responseType: 'code' //'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
});


const currentConfig = Auth.configure();

export default currentConfig;